
:root {
  --fc-list-event-dot-width: 10px;
  --fc-list-event-hover-bg-color: #f5f5f5;
}
.fc-theme-standard .fc-list {
    border: 1px solid #ddd;
    border: 1px solid var(--fc-border-color, #ddd);
  }
.fc {

  /* message when no events */

}
.fc .fc-list-empty {
    background-color: rgba(208, 208, 208, 0.3);
    background-color: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; /* vertically aligns fc-list-empty-inner */
  }
.fc .fc-list-empty-cushion {
    margin: 5em 0;
  }
.fc {

  /* table within the scroller */
  /* ---------------------------------------------------------------------------------------------------- */

}
.fc .fc-list-table {
    width: 100%;
    border-style: hidden; /* kill outer border on theme */
  }
.fc .fc-list-table tr > * {
    border-left: 0;
    border-right: 0;
  }
.fc .fc-list-sticky .fc-list-day > * { /* the cells */
      position: sticky;
      top: 0;
      background: #fff;
      background: var(--fc-page-bg-color, #fff); /* for when headers are styled to be transparent and sticky */
    }
.fc {

  /* only exists for aria reasons, hide for non-screen-readers */

}
.fc .fc-list-table thead {
    position: absolute;
    left: -10000px;
  }
.fc {

  /* the table's border-style:hidden gets confused by hidden thead. force-hide top border of first cell */

}
.fc .fc-list-table tbody > tr:first-child th {
    border-top: 0;
  }
.fc .fc-list-table th {
    padding: 0; /* uses an inner-wrapper instead... */
  }
.fc .fc-list-table td,
  .fc .fc-list-day-cushion {
    padding: 8px 14px;
  }
.fc {


  /* date heading rows */
  /* ---------------------------------------------------------------------------------------------------- */

}
.fc .fc-list-day-cushion:after {
  content: "";
  clear: both;
  display: table; /* clear floating */
    }
.fc-theme-standard .fc-list-day-cushion {
    background-color: rgba(208, 208, 208, 0.3);
    background-color: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
  }
.fc-direction-ltr .fc-list-day-text,
.fc-direction-rtl .fc-list-day-side-text {
  float: left;
}
.fc-direction-ltr .fc-list-day-side-text,
.fc-direction-rtl .fc-list-day-text {
  float: right;
}
/* make the dot closer to the event title */
.fc-direction-ltr .fc-list-table .fc-list-event-graphic { padding-right: 0 }
.fc-direction-rtl .fc-list-table .fc-list-event-graphic { padding-left: 0 }
.fc .fc-list-event.fc-event-forced-url {
    cursor: pointer; /* whole row will seem clickable */
  }
.fc .fc-list-event:hover td {
    background-color: #f5f5f5;
    background-color: var(--fc-list-event-hover-bg-color, #f5f5f5);
  }
.fc {

  /* shrink certain cols */

}
.fc .fc-list-event-graphic,
  .fc .fc-list-event-time {
    white-space: nowrap;
    width: 1px;
  }
.fc .fc-list-event-dot {
    display: inline-block;
    box-sizing: content-box;
    width: 0;
    height: 0;
    border: 5px solid #3788d8;
    border: calc(var(--fc-list-event-dot-width, 10px) / 2) solid var(--fc-event-border-color, #3788d8);
    border-radius: 5px;
    border-radius: calc(var(--fc-list-event-dot-width, 10px) / 2);
  }
.fc {

  /* reset <a> styling */

}
.fc .fc-list-event-title a {
    color: inherit;
    text-decoration: none;
  }
.fc {

  /* underline link when hovering over any part of row */

}
.fc .fc-list-event.fc-event-forced-url:hover a {
    text-decoration: underline;
  }
